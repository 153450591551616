import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Box,
  Button,
  Form,
  FormBody,
  FormFooter,
  FormGroup,
  Input,
  InputAddonNumberControls,
  InputAddonText,
  InputMessage,
  InputRange,
  LoadingOr,
  Paragraph,
  themeUtils as tu,
  useUpDownNumberControl,
} from '@zen-common/components-base';
import { ValueDisplay } from '@zen/common-utils';
import { convertPercentageToZP, convertZPtoPercentage } from '../../store/modules/utils';

export default function AllocationForm({
  minPercentage = 0,
  maxPercentage = 90,
  tallyBlock,
  prev,
  onVote,
  progress,
  disabled,
}) {
  const [allocation, setAllocation] = useState(ValueDisplay.create(''));
  const [allocationPercentage, setAllocationPercentage] = useState(ValueDisplay.create(''));
  //change from number to percentile changes, every step in the slider is then multiplied to the nuymber it is
  const formDisabled = progress || disabled;
  const { min, max } = useMemo(() => {
    const min = convertPercentageToZP(minPercentage, tallyBlock);
    const max = convertPercentageToZP(maxPercentage, tallyBlock);
    return { min, max };
  }, [minPercentage, maxPercentage, tallyBlock]);
  const handleChangeInput = useCallback(
    (value) => {
      value = parseFloat(value); // Parse the value as a float
      if (isNaN(value)) {
        value = ''; // Handle invalid input
      } else {
        // Ensure the value respects min and max
        if (value < min) value = min;
        if (value > max) value = max;
      }
      setAllocationPercentage((a) =>
        a.onDisplayChange(convertZPtoPercentage(String(value), tallyBlock))
      );
      setAllocation((a) => a.onDisplayChange(String(value)));
    },
    [min, max, tallyBlock]
  );

  const handleChangeSlider = useCallback(
    (value) => {
      setAllocation((a) => a.onDisplayChange(convertPercentageToZP(String(value), tallyBlock)));
      setAllocationPercentage((a) => a.onDisplayChange(String(value)));
    },
    [tallyBlock]
  );

  const valid = useMemo(() => allocation.safeValue >= min && allocation.safeValue <= max, [
    allocation.safeValue,
    max,
    min,
  ]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (valid && !progress) {
      typeof onVote === 'function' && (await onVote({ allocation: allocation.safeValue }));
    }
  };

  const color = !allocation.display ? 'label' : valid ? 'primary' : 'danger';

  const { onKeyDown } = useUpDownNumberControl({
    min,
    max,
    value: allocation.safeValue,
    onChange: handleChangeInput,
  });
  return (
    <Box>
      <StyledParagraph>
        You may vote to move the allocation amount up to 15%. Previous CGP allocation was {prev} ZP
        per block. Vote for at least {min} ZP to at most {max} ZP per block to the CGP.
      </StyledParagraph>
      <Form onSubmit={onSubmit} disabled={formDisabled}>
        <FormBody>
          <StyledRow>
            <SliderFormGroup>
              <SliderTextsContainer>
                <SliderTitle>CGP Allocation</SliderTitle>
                <SliderTitle>Miner Reward</SliderTitle>
              </SliderTextsContainer>
              <SliderTextsContainer>
                <SliderMinMax>{min} ZP</SliderMinMax>
                <SliderMinMax>{max} ZP</SliderMinMax>
              </SliderTextsContainer>
              <InputRange
                min={minPercentage}
                max={maxPercentage}
                label="ZP"
                overValue={Number(allocation.safeValue)}
                value={Number(allocationPercentage.safeValue)}
                onChange={handleChangeSlider}
                sliderColor={color}
                bg={color}
                disabled={formDisabled}
              />
            </SliderFormGroup>
            <AmountFormGroup>
              <StyledInput
                placeholder="Enter amount"
                autoComplete="off"
                valid={allocation.display ? valid : undefined}
                variant="block"
                value={allocation.display}
                type="text"
                disabled={formDisabled}
                onChange={(e) => handleChangeInput(e.target.value.trim())}
                onKeyDown={onKeyDown}
                renderRightAddon={
                  <>
                    <InputAddonText>
                      / {min}-{max}
                    </InputAddonText>
                    <InputAddonNumberControls
                      onChange={handleChangeInput}
                      value={allocation.safeValue}
                      min={min}
                      max={max}
                      step="0.5"
                    />
                  </>
                }
              />
              {/* Add an InputMessage for spacing */}
              <InputMessage />
            </AmountFormGroup>
          </StyledRow>
          <FormFooter>
            <Button type="submit" disabled={!valid || progress}>
              <LoadingOr loading={progress} loadingContent="Voting...">
                Vote
              </LoadingOr>
            </Button>
          </FormFooter>
        </FormBody>
      </Form>
    </Box>
  );
}

const StyledParagraph = styled(Paragraph)`
  font-size: ${tu.fontSize('md')};
  ${tu.mq({
    maxWidth: ['auto', null, '70%'],
  })}
`;

const StyledRow = styled(FormGroup)`
  display: flex;

  ${tu.mq({
    flexDirection: ['column', null, 'row'],
    alignItems: [null, null, 'center'],
  })}
`;

const SliderFormGroup = styled(FormGroup)`
  flex-grow: 1;
  flex-shrink: 0;
  ${css(
    tu.mq({
      width: ['auto', null, '70%'],
      maxWidth: ['auto', null, '70%'],
      marginRight: ['0', null, tu.space('xs')],
      marginBottom: ['40px', null, '0'],
    })
  )}
`;
const AmountFormGroup = styled(FormGroup)`
  ${tu.mq({
    width: ['auto', null, '30%'],
    maxWidth: ['auto', null, '30%'],
  })}
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;

const SliderTextsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${tu.space('xs')};
  font-size: ${tu.fontSize(1)};
`;

const SliderTitle = styled(Box)`
  color: ${tu.color('label')};
`;

const SliderMinMax = styled(Box)`
  color: ${tu.color('text')};
`;
