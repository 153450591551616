import { useMemo } from 'react';
import { Decimal } from 'decimal.js';
import { convertPercentageToZP } from './utils';

/**
 * All computed properties
 */
export default function useComputed({
  cgpCurrentAllocation,
  cgpCurrentAllocationFetched,
  cgpSnapshotBalanceFetched,
  snapshotZpBalanceFetched,
  candidatesFetched,
  initialVotesFetchDone,
  blockNumber,
}) {
  return {
    ...useAllocationMinMax({ cgpCurrentAllocation, blockNumber }),
    ...useIsReadyForVote({
      cgpCurrentAllocationFetched,
      cgpSnapshotBalanceFetched,
      snapshotZpBalanceFetched,
      candidatesFetched,
      initialVotesFetchDone,
    }),
  };
}

function useAllocationMinMax({ cgpCurrentAllocation, blockNumber } = {}) {
  return useMemo(() => {
    const lastCoinbaseRatio = new Decimal(100 - cgpCurrentAllocation.percentage);
    const correctionCap = new Decimal(85); // env.coinbaseCorrectionCap = 15
    const globalRatioMin = new Decimal(10); // env.lowerCoinbaseBound = 90
    const localRatioMin = lastCoinbaseRatio.times(correctionCap).div(100).floor();
    const localRatioMax = lastCoinbaseRatio.times(100).div(correctionCap).floor();

    const allocationPercentageMax = 100 - Decimal.max(globalRatioMin, localRatioMin).toNumber();
    const allocationPercentageMin = 100 - Decimal.min(new Decimal(100), localRatioMax).toNumber();

    const allocationZpMax = convertPercentageToZP(allocationPercentageMax, blockNumber);
    const allocationZpMin = convertPercentageToZP(allocationPercentageMin, blockNumber);
    return { allocationZpMax, allocationZpMin, allocationPercentageMax, allocationPercentageMin };
  }, [cgpCurrentAllocation.percentage, blockNumber]);
}

/**
 * Aggregates all flags for needed data for voting
 */
function useIsReadyForVote({
  cgpSnapshotBalanceFetched,
  cgpCurrentAllocationFetched,
  snapshotZpBalanceFetched,
  candidatesFetched,
  initialVotesFetchDone,
}) {
  return {
    isReadyForNomination: useMemo(
      () => cgpSnapshotBalanceFetched && snapshotZpBalanceFetched && initialVotesFetchDone,
      [cgpSnapshotBalanceFetched, initialVotesFetchDone, snapshotZpBalanceFetched]
    ),
    isReadyForVoting: useMemo(
      () =>
        cgpSnapshotBalanceFetched &&
        cgpCurrentAllocationFetched &&
        snapshotZpBalanceFetched &&
        candidatesFetched &&
        initialVotesFetchDone,
      [
        cgpCurrentAllocationFetched,
        cgpSnapshotBalanceFetched,
        snapshotZpBalanceFetched,
        candidatesFetched,
        initialVotesFetchDone,
      ]
    ),
  };
}
