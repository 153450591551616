import { BlockchainInfoStore, ConfigStore, SettingsStore } from '@zen/common-app-parts';
import useConstants from './modules/useConstants';
import useCgpFetch from './modules/useCgpFetch';
import useComputed from './modules/useComputed';

/**
 * @returns {CgpStore}
 */
export function useCgpStore() {
  const config = ConfigStore.useStore();
  const {
    state: {
      settings: { chain, nodeUrl },
    },
  } = SettingsStore.useStore();
  const {
    state: { infos },
  } = BlockchainInfoStore.useStore();
  const { headers } = infos;

  const constants = useConstants({ chain, headers, config });
  const fetchedData = useCgpFetch({ ...constants, config, chain, nodeUrl });
  const computed = useComputed({ ...constants, ...fetchedData });

  return { ...constants, ...fetchedData, ...computed };
}

/**
 * @typedef {{
 *  contractIdCgp: string,
 *  contractIdVote: string,
 *  addressCgp: string,
 *  addressVote: string,
 *  intervalLength: number,
 *  votingIntervalLength: number,
 *  currentInterval: number,
 *  intervalDisplay: number,
 *  snapshotBlock: number,
 *  tallyBlock: number,
 *  nominationBlock: number,
 *  voteStatus: ('nomination'|'voting'|'between'),
 *  thresholdZp: string,
 *
 *  votedAllocation: boolean,
 *  votedNomination: boolean,
 *  votedPayout: boolean,
 *  setVotedAllocation: React.Dispatch<React.SetStateAction<boolean>>,
 *  setVotedNomination: React.Dispatch<React.SetStateAction<boolean>>,
 *  setVotedPayout: React.Dispatch<React.SetStateAction<boolean>>,
 *
 *  initialVotesFetchDone: boolean,
 *  nominationVote: string,
 *  allocationVote: string,
 *  payoutVote: string,
 *  refetchVotes: () => void,
 *  nominees: [{ ballot: string, zpAmount: string }],
 *  cgpSnapshotBalance: { [asset: string]: string },
 *  cgpSnapshotBalanceFetched: boolean,
 *  candidates: [{ ballot: string, zpAmount: string }],
 *  candidateBallots: [string],
 *  candidatesFetched: boolean,
 *  snapshotZpBalance: string,
 *  snapshotZpBalanceFetched: boolean,
 *  cgpCurrentBalance: Object,
 *  cgpCurrentAllocation: { percentage: number, zp: number },
 *  cgpCurrentAllocationFetched: boolean,
 *
 *  allocationZpMax: number,
 *  allocationZpMin: number,
 *  allocationPercentageMax: number,
 *  allocationPercentageMin: number,
 *  isReadyForNomination: boolean,
 *  isReadyForVoting: boolean,
 * }} CgpStore
 */
